import {useEffect, useState} from 'react';

/**
 * This is a React hook used for a parallax effect.
 * Assign the return object to the style prop of an element for parallax!
 * @param {number} multiplier - how 'notcible' the effect is (can be negative)
 * @return {object} - the parallax style to be applied
 */
export function useParallax(multiplier) {
  const [offset, setOffset] = useState(0);

  const style = {transform: `translateY(${offset * multiplier}px)`};

  useEffect(() => {
    const handleScroll = () => setOffset(window.pageYOffset);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return style;
}
