import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useLocation} from '@reach/router';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import {Col, Container, Row} from 'react-bootstrap';
import {graphql, Link} from 'gatsby';


import {usePageContent} from 'src/functionality/content-provider';
import Page from 'src/components/functional/page';
import Navigation from 'src/components/functional/navigation';
import ContentBlock from 'src/components/functional/content-block';
import WhatWeDoElement from '../components/functional/what-we-do-element';
import ImageSlider from 'src/components/functional/image-slider';
import PurposeCircle from 'src/components/functional/purpose-circle';
import ArbiasButton from 'src/components/functional/arbias-button';
import ContentTag from 'src/components/functional/content-tag';
import CaseStudyImage from 'src/components/functional/case-study-image';
import {H3, H4, H5} from 'src/components/functional/headings';
import SEO from 'src/components/seo';

import BgCircle from 'src/components/graphical/bg-circle';
import Hr from 'src/components/graphical/hr';
import Spacer from 'src/components/graphical/spacer';

import TriangleCurve from 'src/components/graphical/triangle-curve';

import 'github-markdown-css';

import {theme} from 'src/styles/theme';
import {breakpoints} from 'src/styles/breakpoints';

import 'styles/index.scss';
import {useFeatureFlags} from 'src/functionality/flags-provider';

import OurPhilosophyImage from 'images/rock-tower.jpg';
import History1Image from 'images/paxton-house.jpg';
import History2Image from 'images/stairs.jpg';
import History3Image from 'images/arbias-office.jpg';
import Strip from 'src/components/functional/strip';

/*
  TODO: Add descriptive comments as to the look and functionality of
        each component
  TODO: remove all static inline styles
*/

const Main = styled.main`
  position: relative;
`;

const HeroSection = styled.section`
  padding: 0 1%;
  width: 100%;
  margin: 0;
  
  @media ${breakpoints.md} {
    padding-left: 0;
  }
`;

const LargeHeading = styled.h1`
  font-size: min(20vw, 16.5em);
  letter-spacing: -0.09em;
  font-weight: 500;
  color: ${theme.primary};
`;

const LargeSubheading = styled.h2`
  text-align: right;
  width: 101%; // This stops some weird looking text breaking
`;

const TextPrimary = styled.span`
  color: ${theme.primary};
`;

const BackgroundAngle1 = styled.div`
  position: absolute;
  z-index: -100;
  top: 15rem;
  left: 0;
  right: 0;
  background-color: ${theme.xlight};
  width: auto;
  height: 130vh;
  transform: skewY(8deg);
  @media ${breakpoints.md}{
    display: none;
  }
`;

const HeroBgCircle = styled(BgCircle)`
  top: max(42.5rem, 80vh)!important;
  @media ${breakpoints.md}{
    display: none;
  }
`;

const HistoryCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const BackgroundAngle2 = styled.div`
  position: absolute;
  z-index: -100;
  left: 0;
  right: 0;
  background-color: ${theme.gray};
  width: auto;
  height: 250vh;
  transform: skewY(-7deg);
`;

const BackgroundAngle3 = styled.div`
  position: absolute;
  z-index: -100;
  top: 20vh;
  left: 0;
  right: 0;
  background-color: ${theme.xlight};
  width: auto;
  height: 200vh;
  transform: skewY(9deg);
`;


const WhoSection = styled.section`
  min-height: 22.5vh;
  @media ${breakpoints.xl}{
    padding-left: 5em;
  }
  @media ${breakpoints.lg}{
    padding-left: 0;
  }
`;

const HrAlt1 = styled((props) => <Hr {...props} />)`
  margin-left: -100%;
`;

const BackgroundDiv = styled.div`
  position: relative;
  `;

const PurposeSection = styled.section`
  position: relative;
  color: ${theme.light};
`;

const PurposeRow = styled(Row)`
  align-items: center;
`;

const CaseStudy = styled.div`
  padding-left: 4rem;
  @media ${breakpoints.lg}{
    padding-left: 0;
  }
`;

const SpacerAlt1 = styled((props) => <Spacer {...props} />)`
  @media ${breakpoints.xl}{
    display: none;
  }
`;

const HrAlt2 = styled((props) => <span {...props}><Hr heavy dark/></span>)`
  position: absolute;
  right: 5vw;
  top: 5vh;
  width: 60vw;
  z-index: -100;
`;

const ProgramSection = styled.section`
  z-index: 10;
  padding: 0 1vw;
`;

const OurPhilosophySection = styled.section`
  background-color: white;
  clear: both;
  padding: 0;
`;

const HeadingLine = styled.div`
  width: 0;
  border-left: 1.5px solid ${theme.dark};
  height: 7.5vh;
  margin: auto;
`;

const ProgramHeading = styled.h3`
  color: ${theme.primary};
  text-align: center;
`;

const Center = styled.div`
  text-align: center;
`;

const DarkSeparator = styled.div`
  background-color: ${theme.xdarkGray};
  position: relative;
  height: 24vh;
  display: flex;
  flex-direction: column;
  &:after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: calc(25vw  + 5em);
    left: 10%;
    // transform: translateX(-50%);
    height: 10vh;
    background-color: ${theme.gray};
    z-index: 1;
  }
  
  box-shadow: 0px 20px 10px 0px rgba(0,0,0,0.51);
`;

const ShowHistoryHeading = styled.button`
  font-size: 3em;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  font-weight: lighter;
  text-transform: uppercase;
  color: ${theme.white};
  display: block;
  margin: auto;
  z-index: 2;
  &:hover{
    color: ${theme.mediumGray};
  }
  &:active{
    color: ${theme.mediumDarkGray};
  }
`;

const HistorySectionClip = styled.div`
  clip-path: polygon(
      0% 0%, 
      60% 0%, 
      calc(100% - 5%) 70%, 
      calc(100% - 5%) 100%,
      0% 100%);
  @media ${breakpoints.lg}{
    clip-path: polygon(
      0% 0%, 
      70% 0%, 
      calc(100% - 5%) 70%, 
      calc(100% - 5%) 100%,
      0% 100%);
  }
  @media ${breakpoints.md}{
    clip-path: unset;
  }
  clip-path: ${(props) => !props.showHistory && 'unset'};
  background: ${theme.bodyBg};
`;
const HistorySection = styled.section`
  background: white;
  
`;


const HistoryContainer = styled(Container)`
  display: ${(props) => props.visible ? 'unset' : 'none'};
`;

const HistorySectionWrapper = styled.div`
  padding: 5% 0;
  background-color: ${theme.primary};
  padding: 0!important;
  position: relative;
  overflow: hidden;
  
  @media ${breakpoints.sm}{
    margin-bottom: 10em;
  }
`;

const Fig = styled.figure`
  margin: 0 auto;
`;

const HistoryCircle = styled((props) => <BgCircle {...props} />)`
  z-index: initial;
  display: ${(props) => props.showHistory ? 'unset' : 'none'};
`;

const NewClientsSection = styled.section`
  padding: 5%;
  border-radius: 4px;
  background-color: ${theme.lightPrimary};
`;

const IndexPage = ({data, pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const flags = useFeatureFlags();

  const [showHistory, setShowHistory] =
    useState(!flags.Home_EnableHistoryAccordion);

  return (
    <Page home={true}>
      <Container className="root-container">
        <Navigation variant="horizonatal" />
        <HeroSection>
          <LargeHeading>People.</LargeHeading>
          <LargeSubheading>
            We enable
            <TextPrimary> people</TextPrimary>
            &nbsp;&#038;&nbsp;
            <TextPrimary>partners</TextPrimary>
            <br />
            to achieve their very <TextPrimary>best</TextPrimary>
          </LargeSubheading>
        </HeroSection>

        <BgCircle
          size={30}
          color="lightGrey"
          x={10}
          y={40}
          parallaxMultiplier={-0.15}
        />
      </Container>
      <Spacer height={2} />
      <ImageSlider
        height={25}
        className="bg-element"
        urls={data.allFile.edges.map((edge) => edge.node)}
      />
      <Main>
        <BackgroundAngle1 />
        <BgCircle
          size={50}
          color="lightGrey"
          className="shadow-sm"
          x={-8.5}
          y={8.5}
          parallaxMultiplier={0.25}
        />
        <Spacer height={22.5} />
        <HeroBgCircle
          size={20}
          color="lightGrey"
          x={5}
          y={80}
          parallaxMultiplier={0.05}
        />
        <BgCircle
          size={30}
          color="lightGrey"
          x={15}
          y={130}
          parallaxMultiplier={0.25}
        />
        <Spacer height={4} />
        <WhoSection className="container root-container">
          <h2 className="display-2 text-primary">
            {content && content.H01?.title}
          </h2>
          <HrAlt1 heavy={true} />
          <Row>
            <Col lg={8} md={10}>
              <ContentTag>H01</ContentTag>
              <Markdown className="lead">
                {content && content.H01?.body}
              </Markdown>
              <ArbiasButton href="/about/">About us</ArbiasButton>
            </Col>
            <Col lg={4} md={2}></Col>
          </Row>
        </WhoSection>
        <BackgroundDiv>
          <BackgroundAngle2 className="diagonal-pattern" />
          <BgCircle
            size={30}
            color="grey"
            x={90}
            y={15}
            shadow
            parallaxMultiplier={0}
          />
          <BgCircle
            size={40}
            color="grey"
            x={65}
            y={105}
            shadow
            parallaxMultiplier={0.1}
          />
        </BackgroundDiv>
        <Spacer height={4}></Spacer>
        <PurposeSection>
          <Container fluid="md" className="root-container">
            <div>
              {/* TODO: Neaten up */}
              <PurposeRow>
                <Col xl={6} lg={8} md={12}>
                  <PurposeCircle title={content && content.H02?.title}>
                    <ContentTag>H02</ContentTag>
                    <Markdown className="text-light">
                      {content && content.H02?.body}
                    </Markdown>
                    <br />
                    <ArbiasButton
                      dark={true}
                      href="/about/our-philosophy/#purpose"
                    >
                      Our Purpose
                    </ArbiasButton>
                  </PurposeCircle>
                </Col>
                <Col xl={6} lg={4} md={12}>
                  <CaseStudy>
                    <SpacerAlt1 height={20} />
                    <Spacer height={10} />
                    <CaseStudyImage clientStoryId={0} />
                    <Spacer height={2} />
                    <ContentTag>H03</ContentTag>
                    {flags.Home_EnableClientStories && (
                      <Markdown
                        className="text-sm"
                        style={{textAlign: 'right'}}
                      >
                        {content && content.H03?.body}
                      </Markdown>
                    )}
                  </CaseStudy>
                </Col>
              </PurposeRow>
              <Hr
                dark={true}
                style={{
                  width: '50vw',
                  right: 0,
                  top: '10vh',
                  position: 'absolute',
                  zIndex: -100,
                }}
              />
              <Hr
                dark={true}
                style={{
                  width: '65vw',
                  left: 0,
                  top: '30vh',
                  position: 'absolute',
                  zIndex: -100,
                }}
              />
              <BgCircle
                size={65}
                color="white"
                x={55}
                y={-35}
                shadow
                parallaxMultiplier={-0.05}
              />
            </div>
          </Container>
        </PurposeSection>
        <BackgroundDiv>
          <HrAlt2 />
          <BackgroundAngle3 />
          <BgCircle
            size={40}
            color="lightGrey"
            className="shadow-sm"
            x={-3}
            y={50}
            parallaxMultiplier={-0.15}
          />
        </BackgroundDiv>
        <Spacer height={20} />
        <ProgramSection>
          <Container className="root-container">
            <Spacer height={4} />
            <HeadingLine />
            <ProgramHeading className="display-3">What We Do</ProgramHeading>
            <Spacer height={2} />
            <Row className="justify-content-center">
              {(flags.Home_UseWhatWeDoCategoriesNotServices ?
                ['H07-1', 'H07-2', 'H07-3'] :
                ['H07A', 'H07B', 'H07C', 'H07D', 'H07E']
              ).map((tag, key) => {
                return (
                  <WhatWeDoElement
                    xl={4}
                    lg={6}
                    sm={12}
                    key={key}
                    number={key}
                    heading={content && content[tag]?.title}
                  >
                    <ContentBlock
                      header={() => <></>}
                      content={content}
                      tag={tag}
                    />
                  </WhatWeDoElement>
                );
              })}
            </Row>
            <Center>
              <ArbiasButton to="/about/what-we-do">Learn More</ArbiasButton>
            </Center>
          </Container>
        </ProgramSection>
        <Spacer height={-10} />
        <TriangleCurve left></TriangleCurve>
        <BackgroundDiv>
          <BgCircle
            size={90}
            color="lightGrey"
            x={0}
            y={-55}
            parallaxMultiplier={0.1}
          />
        </BackgroundDiv>
        <OurPhilosophySection>
          <Container>
            <Spacer height={2} />
            <Row>
              <Col xl={8} lg={6}>
                <h2 className="display-4 text-primary">
                  Our
                  <br />
                  Philosophy
                </h2>
                <div>
                  <ContentTag>H04</ContentTag>
                  <Markdown className="lead">
                    {content && content.H04?.body}
                  </Markdown>
                </div>
                <br />
                <br />
                <br />
                <div style={{padding: '0 3vw'}}>
                  <small>
                    <ContentTag>H05</ContentTag>
                    <Markdown>{content && content.H05?.body}</Markdown>
                  </small>
                </div>
              </Col>
              <Col xl={4} lg={6}>
                <Row>
                  <Fig>
                    <img
                      src={OurPhilosophyImage}
                      // alt="TODO:"
                    />
                  </Fig>
                </Row>
                <Row>
                  <div>
                    <Spacer height={2} />
                    <ArbiasButton right={true} to="/about/our-philosophy/">
                      More on our philosophy
                    </ArbiasButton>
                  </div>
                </Row>
              </Col>
            </Row>
            <Spacer height={2} />
          </Container>
        </OurPhilosophySection>
        <DarkSeparator className="diagonal-pattern">
          {flags.Home_EnableHistoryAccordion && (
            <ShowHistoryHeading onClick={() => setShowHistory(!showHistory)}>
              History{' '}
              {showHistory ? (
                <i className="fa fa-angle-up" />
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </ShowHistoryHeading>
          )}
        </DarkSeparator>
        <HistorySection showHistory={showHistory}>
          <HistorySectionWrapper>
            {/* TODO: convert this into a js object/array and iterate */}
            <HistoryCircle
              showHistory={showHistory}
              size={45}
              color="blue"
              x={90}
              y={-15}
              parallaxMultiplier={0}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={15}
              color="blue"
              x={70}
              y={5}
              parallaxMultiplier={0.1}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={15}
              color="lightBlue"
              x={80}
              y={-90}
              parallaxMultiplier={0.25}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={30}
              color="lightBlue"
              x={55}
              y={20}
              parallaxMultiplier={0}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={15}
              color="darkBlue"
              x={90}
              y={100}
              parallaxMultiplier={-0.05}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={15}
              color="lightBlue"
              x={95}
              y={175}
              parallaxMultiplier={0.05}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={45}
              color="blue"
              x={70}
              y={120}
              parallaxMultiplier={0}
            />
            <HistoryCircle
              showHistory={showHistory}
              size={15}
              color="darkBlue"
              x={97.5}
              y={110}
              parallaxMultiplier={0.1}
            />
            <HistorySectionClip showHistory={showHistory}>
              <HistoryContainer
                className="root-container"
                fluid
                visible={showHistory ? 'true' : undefined}
              >
                <div
                  className="history-wrapper"
                  style={{width: '60%', padding: '0 1%'}}
                >
                  <h2 className="display-2 text-primary">History</h2>
                  <span className="history-rule">
                    <Hr heavy />
                  </span>
                  <Row>
                    <HistoryCol
                      lg={8}
                      className="d-flex flex-column justify-content-around"
                      style={{marginBottom: '4vh'}}
                    >
                      <div>
                        <H5>{content && content.H19?.title}</H5>
                        <br />
                        <ContentTag>H19</ContentTag>
                        <Markdown>{content && content.H19?.body}</Markdown>
                      </div>
                    </HistoryCol>
                    <HistoryCol lg={4}>
                      <img
                        className="home-history-img"
                        src={History1Image}
                        // alt="" // TODO:
                      />
                    </HistoryCol>
                  </Row>
                  <Spacer height={8}></Spacer>
                  <Row
                    style={{
                      borderRight: 'black 1px solid',
                      padding: '0 1%',
                      width: '115%',
                    }}
                    className="flex-wrap-reverse"
                  >
                    <HistoryCol lg={4}>
                      <img
                        className="home-history-img"
                        src={History2Image}
                        alt="TODO:"
                      />
                    </HistoryCol>
                    <HistoryCol
                      lg={8}
                      className="d-flex flex-column justify-content-around"
                      style={{marginBottom: '4vh'}}
                    >
                      <div className="text-end">
                        <H5 right={true}>{content && content.H09?.title}</H5>
                        <br />
                        <ContentTag>H09</ContentTag>
                        <Markdown>{content && content.H09?.body}</Markdown>
                      </div>
                    </HistoryCol>
                  </Row>
                  <Spacer height={8}></Spacer>
                  <span
                    className="history-rule"
                    style={{
                      position: 'absolute',
                      marginTop: '-5vh',
                      left: 0,
                      width: '20%',
                    }}
                  >
                    <Hr heavy />
                  </span>
                </div>
                <Spacer height={4} />
                <div
                  style={{
                    padding: '0 5%',
                    position: 'relative',
                    borderLeft: 'black 1px solid',
                    margin: '0 5%',
                    width: '70%',
                  }}
                >
                  <h3 className="display-4 text-primary">arbias Today</h3>
                  <span
                    className="history-rule"
                    style={{
                      position: 'absolute',
                      left: '1em',
                      top: '3em',
                      width: '50vw',
                    }}
                  >
                    <Hr heavy />
                  </span>
                  <Spacer height={2}></Spacer>
                  <Row style={{padding: '0 1%', width: '125%'}}>
                    <HistoryCol lg={4}>
                      <img
                        className="home-history-img"
                        src={History3Image}
                        // alt="" // TODO:
                      />
                    </HistoryCol>
                    <HistoryCol
                      lg={8}
                      className="d-flex flex-column justify-content-center"
                    >
                      <div className="text-end">
                        <ContentBlock
                          header={(props) => <H5 {...props} />}
                          content={content}
                          tag="H17"
                        />
                      </div>
                    </HistoryCol>
                  </Row>
                </div>
              </HistoryContainer>
              <Spacer height={4} />
              <Strip bg={theme.mediumGray} padding="4vh">
                <div>
                  <ContentBlock
                    header={(props) => <H3 {...props} />}
                    content={content}
                    tag="H18"
                  />
                  <ArbiasButton href="/information/">
                    Information Resources
                  </ArbiasButton>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {flags.EnableInformationExplorer && (
                    <ArbiasButton href="/information/explorer/">
                      Information Explorer
                    </ArbiasButton>
                  )}
                </div>
              </Strip>
              <Spacer height={4} />
              <Container>
                <NewClientsSection>
                  <Row className="text-lg-start">
                    <Col xl={4} lg={6} sm={12}>
                      <H4>For new clients</H4>
                    </Col>
                    <Col xl={8} lg={6} sm={12}>
                      <ArbiasButton href="/information/#I07">
                        How it works &gt;
                      </ArbiasButton>
                      &nbsp;&nbsp;
                      <ArbiasButton href="/information/#I06">
                        Getting a referral &gt;
                      </ArbiasButton>
                    </Col>
                  </Row>
                </NewClientsSection>
              </Container>
              <Spacer height={4} />
              <Container>
                <Row className="text-lg-center">
                  <Col lg={2}/>
                  <Col lg={4} sm={12} style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                    <H4 noUnderline>Feedback</H4>
                  </Col>
                  <Col lg={4} sm={12}>
                    <ArbiasButton
                      href="/information/complaints-and-compliments/"
                    >
                      Compliments or complaints &gt;
                    </ArbiasButton>
                  </Col>
                  <Col lg={2}/>
                </Row>
              </Container>
              <Spacer height={2} />
              <Container><hr /></Container>
              <Spacer height={2} />
              <div className="whats-next">
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    zIndex: -10,
                  }}
                >
                  <TriangleCurve right={true} />
                </div>
                <div className="whats-next-wrapper">
                  <Container className="root-container text-center">
                    {/* TODO: Modularise */}
                    <H3 display={5}>What&apos;s Next?</H3>
                    <Row>
                      <Col md={4} className="whats-next-col">
                        <div>
                          <Spacer height={2} />
                          <h4>
                            <Link to="/about/" className="text-dark">
                              About
                            </Link>
                          </h4>
                          <ul>
                            <li>
                              <Link to="/about/what-we-do/">What We Do</Link>
                            </li>
                            <li>
                              <Link to="/about/people">People</Link>
                            </li>
                            <li>
                              <Link to="/about/our-philosophy/">
                                Our Philosophy
                              </Link>
                            </li>
                            <li>
                              <br />
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md={4} className="whats-next-col">
                        <div>
                          <Spacer height={2} />
                          <h4>
                            <Link to="/corporate/" className="text-dark">
                              Corporate
                            </Link>
                          </h4>
                          <ul>
                            <li>
                              <Link to="/about/people/management-and-advisors">
                                Governance
                              </Link>
                            </li>
                            <li>
                              <Link to="/about/people/management-and-advisors">
                                Managers and advisors
                              </Link>
                            </li>
                            <li>
                              <Link to="/about/resources/">
                                Company reports and policies
                              </Link>
                            </li>
                            <li>
                              <br />
                            </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md={4} className="whats-next-col">
                        <div>
                          <h4>More</h4>
                          <ul>
                            <li>
                              <Link to="/about/resources/">
                                Documents &amp; Resources
                              </Link>
                            </li>
                            {flags.EnableInformationExplorer && (
                              <li>
                                <Link to="/information/explorer/">
                                  Information Explorer
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to="/about/publications/">
                                News and Announcements
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Spacer height={4} />
                </div>
              </div>
            </HistorySectionClip>
          </HistorySectionWrapper>
        </HistorySection>
      </Main>
    </Page>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export const query = graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "home/client-faces"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              aspectRatio: 0.7
              transformOptions: {
                grayscale: true,
                fit: INSIDE,
                cropFocus: ATTENTION
              }
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
`;

export default IndexPage;

// export const Head = () => <SEO />;
