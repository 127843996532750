import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';

const Line = styled.hr`
  border-top: 
    ${(props) => props.heavy ? '2px' : '1px'}            // Thickness
    solid                                                // Line style
    ${(props) => props.dark ? theme.dark : theme.light}; // Colour
`;

const Hr = (props) => {
  return (
    <>
      <Line style={{...props.style}} dark={props.light || !props.dark}/>
    </>
  );
};

Hr.propTypes = {
  dark: PropTypes.bool,
  light: PropTypes.bool,
  style: PropTypes.object,
};

export default Hr;
