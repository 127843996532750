import PropTypes from 'prop-types';
import React from 'react';
import TriangleCurveImage from 'src/images/triangle-curve.svg';
import styled from 'styled-components';

const TriangleWrapper = styled.div`
  width: 100%!important;
  margin-top: -8.4vw;
  margin: 0;
  pointer-events: none;
`;

const Image = styled.img`
  transform: scaleX(${(props) => props.direction === 'left' ? 1 : -1});
`;


const TriangleCurve = (props) => {
  let direction;
  if (props.right) {
    direction = 'right';
  } else if (props.left) {
    direction = 'left';
  }

  return (
    <TriangleWrapper style={props.style}>
      <Image
        direction={direction} src={TriangleCurveImage}
        alt="blue triangular geometric design curve"
      />
    </TriangleWrapper>
  );
};

TriangleCurve.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
  style: PropTypes.object,
};

export default TriangleCurve;
