import PropTypes from 'prop-types';
import React from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import styled from 'styled-components';
import {theme} from 'src/styles/theme';

import './image-slider.scss';

const Container = styled.div`
  border-bottom: 0.175rem solid ${theme.secondary};
`;

const ImageWrapper = styled.div`
  height: 100%;
  width:100%;
  & .gatsby-image-wrapper{
    width:100%;
    height: 100%;
    min-width: 10rem;
  }
  & .gatsby-image-wrapper > div{
    max-width: initial!important;
  }
`;

const ImageSlider = (props) => {
  const images = props.urls.map((url) => getImage(url));
  return (
    <Container className={`image-slider ${props.className}`}>
      <div style={{height: `${props.height}vh`}} className="wrapper d-flex">
        <div className="slider-images-a d-flex">
          {images.map((url, i) =>
            <ImageWrapper key={i}>
              <GatsbyImage
                image={url}
                loading="eager"
                alt="client's face"/>
            </ImageWrapper>,
          )}
        </div>

        <div className="slider-images-b d-flex">
          {images.map((url, i) =>
            <ImageWrapper key={i}>
              <GatsbyImage
                image={url}
                loading="eager"
                alt="client's face"/>
            </ImageWrapper>,
          )}
        </div>
      </div>
    </Container>
  );
};

ImageSlider.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  urls: PropTypes.array,
};

export default ImageSlider;
