import PropTypes from 'prop-types';
import React from 'react';
import {Col} from 'react-bootstrap';
import styled from 'styled-components';

import {theme} from 'src/styles/theme';


const Wrapper = styled((props) => <Col {...props} />)`
  padding: 1%;
`;

const Item = styled.div`
  padding: 0.25em;
  margin: 0 2%;
  padding-top: 1em;
  `;

const Heading = styled.h5`
  border-bottom: 1px solid ${theme.mediumGray};
  font-family: 'Sunflower', monospace;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${theme.darkGray};
`;

const WhatWeDoElement = (props) => {
  return (
    <Wrapper {...props}>
      <Item>
        <Heading>
          <span>
            {(props.number + 1).toString().padStart(2, '0')}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {props.heading}
        </Heading>
        <div>
          {props.children}
        </div>
      </Item>
    </Wrapper>
  );
};

WhatWeDoElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.string,
  number: PropTypes.number.isRequired,
};

export default WhatWeDoElement;

// TODO: Make this a styled component
