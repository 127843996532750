import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {useParallax} from 'src/functionality/parallax';
import purposeCircle from 'src/images/home/purpose-circle.svg';

const Circle = styled.div`
  background-image: url(${purposeCircle});
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  
  &:before{
    content:'';
    float:left;
    padding-top:100%;
  }
`;

const TextBlock = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.5vh 5vw!important;
  text-align: center;
`;

// TODO: rename to contentCirle or simmilar

const PurposeCircle = (props) => {
  const parallaxMultiplier=0.05;

  const parallaxStyle = useParallax(parallaxMultiplier);

  return (
    <Circle style={parallaxStyle}>
      <TextBlock>
        <h2 className="display-2 text-primary" style={{textAlign: 'center'}}>
          {props.title}
        </h2>
        {props.children}
      </TextBlock>
    </Circle>
  );
};

PurposeCircle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

export default PurposeCircle;
