import PropTypes from 'prop-types';
import React from 'react';
import {useParallax} from 'src/functionality/parallax';
import {breakpoints} from 'src/styles/breakpoints';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media ${breakpoints.md}{
    display: none;
  }
`;


const BgCircle = (props, style) => {
  // white, light-grey, grey, dark-grey, light-blue, primary, blue, dark-blue;
  const colors = {
    white: '#FFFFFF',
    lightGrey: '#E8E8E8',
    grey: '#626262',
    // darkGrey: "#22252A",
    darkGrey: '#2C2F31',
    lightBlue: '#39C4F7',
    primary: '#00AEEF',
    blue: '#229ADD',
    darkBlue: '#1E69B3'};

  const parallaxStyle = useParallax(props.parallaxMultiplier);

  return (
    <>
      <Wrapper
        className={`bg-circle bg-element 
          ${props.shadow ? 'bg-shadow' : ''} ${props.className}`}
        style={{
          backgroundColor: colors[props.color],
          width: `${props.size/2}vw`,
          height: `${props.size/2}vw`,
          borderRadius: '100%',
          left: `${props.x}vw`,
          top: `${props.y}vh`,
          ...parallaxStyle,
          ...style}}
      />
    </>
  );
};

BgCircle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  parallaxMultiplier: PropTypes.number.isRequired,
  shadow: PropTypes.bool,
  size: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

export default BgCircle;
