import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import Client from 'images/people-1.jpg';

const CaseStudyText = styled.div`
  padding: 2.5em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const LearnMore = styled.div`
  font-size: 1.1em;
`;

const Image = styled.img`
  filter: grayscale(1.0);
  filter: brightness(70%);
`;
const CaseStudyWrapper = styled((props) => <Link {...props} />)`
  position: relative;
  clear: both;
`;

const clientStory = {
  'type': 'clientStory',
  'firstName': 'Steven',
  'lastName': 'Turner',
  'featuredImage': 'https://picsum.photos/325/200',
};

const CaseStudyImage = (props) => {
  const flags = useFeatureFlags();

  return (
  // TODO: fill this link
    <CaseStudyWrapper
      to={flags.Home_EnableClientStories ?
      '/about/people/client-stories/' : '/about/people/'}
      style={{...props.style}}
    >
      <Image
        src={Client}
        alt="TODO:"
      />
      {flags.Home_EnableClientStories &&
        <CaseStudyText>
          <h4 className="display-4 text-primary">
            {clientStory.firstName} {clientStory.lastName}
          </h4>
          <LearnMore>Learn more about their story &gt;</LearnMore>
        </CaseStudyText>
      }
    </CaseStudyWrapper>
  );
};

CaseStudyImage.propTypes = {
  clientStoryId: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default CaseStudyImage;
